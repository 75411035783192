


















import { defineComponent, computed, reactive } from '@vue/composition-api';
import ApexCharts from 'apexcharts';
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const {
      id, title, chartConfigs,
    } = props.data;

    const chartData = reactive([]);

    forEach(chartConfigs, (Obj, index) => {
      const defaultConfig = {
        chart: {
          type: 'line',
          height: 350,
          group: id,
        },
        yaxis: {
          labels: {
            minWidth: 40,
          },
        },
        dataLabels: {
          enabled: false,
        },
      };
      merge(chartConfigs[index].config, defaultConfig);
      // Object.assign(chartConfigs[index] {mer})
      chartData.push(chartConfigs[index]);
    });

    return {
      title,
      chartData,
    };
  },
  mounted() {
    this.chartData.forEach(item => {
      const chart = new ApexCharts(document.getElementById(item.id), item.config);
      chart.render();
    });
  },
});
